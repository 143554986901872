import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Collapse } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { API } from '../../providers/API'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { StyledMenu } from './Slideout.styles'
import logo from '../../media/agro-svg-no-text.svg'
import {
  IconAccount,
  IconCreateField,
  IconInvoices,
  IconLogout,
  IconMyFields,
  IconMyMixes,
  // IconQuestion,
  IconWeatherData,
  IconPrice,
  IconSoilAnalysis,
} from '../../icons/index'
import { IconSateliteData } from '../../icons/IconSateliteData'

type logoutUserProp = {
  // eslint-disable-next-line no-unused-vars
  (redirect?: boolean) : void
}

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  },
  [ref, handler])
}

export const Slideout: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const [collapseMenu, setCollapseMenu] = useState(false)
  const [collapseSatMenu, setCollapseSatMenu] = useState(false)
  const { t } = useTranslation()

  const {
    slideoutOpen,
    setSlideoutOpen, userInfo, userAccess, setUserAccess, setUserInfo, setAuthError,
  } = useContext(GlobalStateContext)

  const logoutUser: logoutUserProp = (redirect = false) => {
    if (redirect) {
      setUserAccess(0)
      ls.set('userAccess', 0)
      setAuthError(false)
    }
    ls.set('loginCredentials', '')
    ls.set('userInfo', '')
    setUserInfo({})
    setIsLoggingOut(false)
  }

  useEffect(() => {
    // @ts-ignore
    if (location.pathname !== '/createMix' && location.pathname !== '/mixes') {
      setCollapseMenu(false)
    }
  }, [location.pathname])

  useEffect(() => {
    // get and set user info and access level on page load to prevent unauthorized access
    if (userInfo.name) {
      API.get(`/userData/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
        ls.set('userAccess', response.data.access)
        setUserAccess(response.data.access)
        setUserInfo(response.data)
        ls.set('userInfo', response.data)
      })
    }
  }, [])

  API.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401 && error.config.method !== 'get' && error.response.data.message !== 'Email or password are not correct!') {
      setSlideoutOpen(false)
      setAuthError(true)
      logoutUser()
    } else if (error.response.status === 401 && error.config.method === 'get') {
      logoutUser(true)
    }

    return Promise.reject(error)
  })

  if (!userInfo.name) {
    return null
  }

  return (
    <StyledMenu open={slideoutOpen}>
      <img src={logo} alt="" className="logo" />
      {userAccess !== 0 && (
        <>
          <ul>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/fields' || location.pathname === '/' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/fields')
                }}
              >
                <IconMyFields />
                {' '}
                <span className="menuText">{t('navigation.myFields')}</span>
              </Button>
            </li>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/createField' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/createField')
                }}
              >
                <IconCreateField />
                {' '}
                <span className="menuText">{t('navigation.createField')}</span>
              </Button>
            </li>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/fieldZones' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/fieldZones')
                }}
              >
                <IconMyFields />
                {' '}
                <span className="menuText">{t('navigation.myZones')}</span>
              </Button>
            </li>
            {/* <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/fieldsData' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/fieldsData')
                }}
              >
                <IconMyFields />
                {' '}
                <span className="menuText">{t('navigation.fieldsDataOverview')}</span>
              </Button>
            </li> */}
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/mixes' || location.pathname === '/createMix' ? 'active' : ''}
                onClick={() => {
                  setCollapseMenu(!collapseMenu)
                }}
                endIcon={<FontAwesomeIcon icon={collapseMenu ? faAngleDown : faAngleRight} />}
              >
                <IconMyMixes size="34" />
                {' '}
                <span className="menuText">{t('navigation.mixes')}</span>
              </Button>
              <Collapse in={collapseMenu} timeout="auto" unmountOnExit>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={location.pathname === '/createMix' && location.search === '?mixType=ai' ? 'active' : ''}
                      onClick={() => {
                        setSlideoutOpen(false)
                        history.push('/createMix?mixType=ai')
                      }}
                    >
                      <span className="css-nodkat" /> {t('navigation.aiMix')}
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={location.pathname === '/createMix' && location.search === '?mixType=manual' ? 'active' : ''}
                      onClick={() => {
                        setSlideoutOpen(false)
                        history.push('/createMix?mixType=manual')
                      }}
                    >
                      <span className="css-nodkat" /> {t('navigation.mix')}
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={location.pathname === '/mixes' ? 'active' : ''}
                      onClick={() => {
                        setSlideoutOpen(false)
                        history.push('/mixes')
                      }}
                    >
                      <span className="css-nodkat" /> {t('navigation.myMixes')}
                    </ListItemButton>
                  </ListItem>
                </List>
                {/* <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  className={location.pathname === '/mixes' ? 'active' : ''}
                  onClick={() => {
                    setSlideoutOpen(false)
                    history.push('/mixes')
                  }}
                >
                  <IconMyMixes size="34" />
                  {' '}
                  <span className="menuText">{t('navigation.myMixes')}</span>
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  className={location.pathname === '/mixes' ? 'active' : ''}
                  onClick={() => {
                    setSlideoutOpen(false)
                    history.push('/mixes')
                  }}
                >
                  <IconMyMixes size="34" />
                  {' '}
                  <span className="menuText">{t('navigation.myMixes')}</span>
                </Button> */}
              </Collapse>
            </li>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/weatherListing' || location.pathname === '/weatherField' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/weatherListing')
                }}
              >
                <IconWeatherData />
                {' '}
                <span className="menuText">{t('navigation.meteoData')}</span>
              </Button>
            </li>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/soilAnalysisListing' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/soilAnalysisListing')
                }}
              >
                <IconSoilAnalysis />
                {' '}
                <span className="menuText">{t('navigation.soilAnalysis')}</span>
              </Button>
            </li>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/ndviFieldsListing' || location.pathname === '/ndviFieldsCompare' ? 'active' : ''}
                onClick={() => {
                  setCollapseSatMenu(!collapseSatMenu)
                }}
                endIcon={<FontAwesomeIcon icon={collapseSatMenu ? faAngleDown : faAngleRight} />}
              >
                <IconSateliteData size="34" />
                {' '}
                <span className="menuText">{t('navigation.sateliteData')}</span>
              </Button>
              <Collapse in={collapseSatMenu} timeout="auto" unmountOnExit>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={location.pathname === '/ndviFieldsListing' ? 'active' : ''}
                      onClick={() => {
                        setSlideoutOpen(false)
                        history.push('/ndviFieldsListing')
                      }}
                    >
                      <span className="css-nodkat" /> {t('navigation.ndviEvi')}
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={location.pathname === '/ndviFieldsCompare' ? 'active' : ''}
                      onClick={() => {
                        setSlideoutOpen(false)
                        history.push('/ndviFieldsCompare')
                      }}
                    >
                      <span className="css-nodkat" /> {t('navigation.ndviCompare')}
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      className={location.pathname === '/sateliteSoilHistory' ? 'active' : ''}
                      onClick={() => {
                        setSlideoutOpen(false)
                        history.push('/sateliteSoilHistory')
                      }}
                    >
                      <span className="css-nodkat" /> {t('navigation.soilHistory')}
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </li>
          </ul>
          <h3>{t('general.profile')}</h3>
          <ul>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/account' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/account/profile')
                }}
              >
                <IconAccount />
                {' '}
                <span className="menuText">{t('navigation.myData')}</span>
              </Button>
            </li>
            {/* <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/carbonEnrollment' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/carbonEnrollment')
                }}
              >
                <IconAccount />
                {' '}
                <span className="menuText">{t('navigation.carbonEnrollment')}</span>
              </Button>
            </li> */}
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/account' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/account/invoices')
                }}
              >
                <IconInvoices />
                {' '}
                <span className="menuText">{t('navigation.invoices')}</span>
              </Button>
            </li>
            <li>
              <Button
                type="button"
                variant="contained"
                fullWidth
                className={location.pathname === '/tokenPackages' ? 'active' : ''}
                onClick={() => {
                  setSlideoutOpen(false)
                  history.push('/tokenPackages')
                }}
              >
                <IconPrice />
                {' '}
                <span className="menuText">{t('navigation.tokenPackages')}</span>
              </Button>
            </li>
            <li>
              <LoadingButton
                type="button"
                variant="contained"
                fullWidth
                onClick={() => {
                  setIsLoggingOut(true)
                  API.post('/logout', {}, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(() => {
                    logoutUser(true)
                  })
                }}
                loading={isLoggingOut}
                loadingPosition="start"
                startIcon={null}
              >
                {!isLoggingOut && <IconLogout />}
                {' '}
                <span className="menuText">{t('navigation.logout')}</span>
              </LoadingButton>
            </li>
          </ul>
        </>
      )}
    </StyledMenu>
  )
}
